<template>
  <b-overlay :show="isLoading">
    <form @submit.prevent="createLine()">
      <div class="mb-3">


        <div class="form-group">
          <label>raison </label>
          <input type="text" :class="errors.raison?'form-control is-invalid':'form-control'"
                 v-model="form.raison">

          <div class="invalid-feedback" v-if="errors.raison">
            <template v-for=" error in errors.raison"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>debut </label>
          <input type="date" :class="errors.debut?'form-control is-invalid':'form-control'"
                 v-model="form.debut">

          <div class="invalid-feedback" v-if="errors.debut">
            <template v-for=" error in errors.debut"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>fin </label>
          <input type="date" :class="errors.fin?'form-control is-invalid':'form-control'"
                 v-model="form.fin">

          <div class="invalid-feedback" v-if="errors.fin">
            <template v-for=" error in errors.fin"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>etats </label>
          <input type="text" :class="errors.etats?'form-control is-invalid':'form-control'"
                 v-model="form.etats">

          <div class="invalid-feedback" v-if="errors.etats">
            <template v-for=" error in errors.etats"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>identifiants_sadge </label>
          <input type="text" :class="errors.identifiants_sadge?'form-control is-invalid':'form-control'"
                 v-model="form.identifiants_sadge">

          <div class="invalid-feedback" v-if="errors.identifiants_sadge">
            <template v-for=" error in errors.identifiants_sadge"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>creat_by </label>
          <input type="text" :class="errors.creat_by?'form-control is-invalid':'form-control'"
                 v-model="form.creat_by">

          <div class="invalid-feedback" v-if="errors.creat_by">
            <template v-for=" error in errors.creat_by"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>users </label>
          <CustomSelect
              :key="form.user"
              :url="`${axios.defaults.baseURL}/api/users-Aggrid`"
              :columnDefs="['libelle']"
              filter-key=""
              filter-value=""
              :oldValue="form.user"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>{form.user_id=data.id;form.user=data}"
          />
          <div class="invalid-feedback" v-if="errors.user_id">
            <template v-for=" error in errors.user_id"> {{ error[0] }}</template>

          </div>
        </div>

      </div>

      <button type="submit" class="btn btn-primary">
        <i class="fas fa-floppy-disk"></i> Créer
      </button>
    </form>
  </b-overlay>
</template>

<script>
import Files from "@/components/Files.vue"

export default {
  name: 'CreatePresences',
  components: {CustomSelect: () => import("@/components/CustomSelect.vue"), Files},
  props: [
    'gridApi',
    'modalFormId',
    'usersData',
  ],
  data() {
    return {
      errors: [],
      isLoading: false,
      form: {

        id: "",

        raison: "",

        debut: "",

        fin: "",

        user_id: "",

        etats: "",

        extra_attributes: "",

        created_at: "",

        updated_at: "",

        deleted_at: "",

        identifiants_sadge: "",

        creat_by: "",
      }
    }
  },
  methods: {
    createLine() {
      this.isLoading = true
      this.axios.post('/api/presences', this.form).then(response => {
        this.isLoading = false
        this.resetForm()
        this.gridApi.applyServerSideTransaction({
          add: [
            response.data
          ],
        });
        this.gridApi.refreshServerSide()
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    resetForm() {
      this.form = {
        id: "",
        raison: "",
        debut: "",
        fin: "",
        user_id: "",
        etats: "",
        extra_attributes: "",
        created_at: "",
        updated_at: "",
        deleted_at: "",
        identifiants_sadge: "",
        creat_by: "",
      }
    }
  }
}
</script>
